import React from "react";

import BreadCrumbs from "@/components/layout/BreadCrumbs/BreadCrumbs";
import PageWrapper from "@/components/layout/PageWrapper/PageWrapper";
import { H1, P } from "@/components/typography";

const NotFoundPage = () => {
  return (
    <PageWrapper
      seo={{
        title: "Oeps! Deze pagina bestaat niet.",
        description:
          "Helaas hebben we de opgevraagde pagina niet kunnen vinden.",
        canonical: "https://www.villapinedo.nl/404",
      }}
    >
      <BreadCrumbs />

      <main style={{ marginBottom: "80px" }}>
        <div className="flex min-h-[50vh] flex-col items-center justify-center">
          <H1 variant="bold">Oeps! Deze pagina bestaat niet.</H1>
          <P>Helaas hebben we de opgevraagde pagina niet kunnen vinden.</P>
        </div>
      </main>
    </PageWrapper>
  );
};

export default NotFoundPage;
