import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import styled from "styled-components";

import { getContentTags } from "@/api/api";
import { Tag } from "@/types/contentTypes";
import { decodeTagFromUrl, getPathFromUrl } from "@/utils/url";

type Props = {
  isDark?: boolean;
  pageTitle?: string;
};

type Breadcrumb = {
  /** Breadcrumb title. Example: 'blog-entries' */
  breadcrumb: string;

  /** The URL which the breadcrumb points to. Example: 'blog/blog-entries' */
  href: string;
};

const StyledNav = styled.nav`
  width: 100%;
  padding: 32px 64px;
  position: absolute;
  z-index: 99;

  ol {
    display: flex;

    li {
      a {
        // color: ${({ theme }) => theme.colors.secondary.normal};
      }

      &:last-child {
        a {
          font-family: Avenir_black !important;
        }
      }
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export default function BreadCrumbs({ isDark = true, pageTitle = "" }: Props) {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[] | null>(null);
  const [categories, setCategories] = useState<Tag[]>([]);

  const convertBreadcrumb = (title: string): React.ReactNode => {
    let transformedTitle = getPathFromUrl(title);

    // decode for utf-8 characters and return ascii.
    return decodeTagFromUrl(transformedTitle);
  };

  useEffect(() => {
    getContentTags()
      .then((res) => res.json())
      .then(({ items }) => setCategories(items));
  }, []);

  useEffect(() => {
    const steunPages = ["verhalen", "audio", "assignment", "video"];

    if (router) {
      const linkPath = router.asPath.split("/");
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        return {
          breadcrumb:
            steunPages.indexOf(path) >= 0 ? "Tips & ervaringen" : path,
          href:
            "/" +
            (steunPages.indexOf(path) >= 0
              ? "tips-&-ervaringen"
              : linkPath.slice(0, i + 1).join("/")),
        };
      });

      if (pageTitle != null && pageTitle != "") {
        pathArray[pathArray.length - 1].breadcrumb = pageTitle;
      }

      setBreadcrumbs(pathArray);
    }
  }, [pageTitle, router]);

  return (
    <StyledNav>
      <ol>
        <li className="with-divider">
          <Link
            href="/"
            style={{ color: isDark ? "#fff" : "#000" }}
            className="flex items-center font-avenir"
          >
            Home
            <FiChevronRight
              style={{ stroke: isDark ? "white" : "black", strokeWidth: "4" }}
              className="mx-2"
            />
          </Link>
        </li>
        {breadcrumbs?.map((breadcrumb, i) => {
          if (!breadcrumb || breadcrumb.breadcrumb.length === 0) {
            return;
          }

          const category = categories.find(
            (category) =>
              decodeTagFromUrl(category.name) ==
              decodeTagFromUrl(breadcrumb.breadcrumb),
          );

          // A category is a breadcrumb that is not the first or last breadcrumb and is in the categories list.
          if (category && i != 0 && i != breadcrumbs.length - 1) {
            return null;
          }

          return (
            <li key={breadcrumb.href}>
              <Link
                href={breadcrumb.href}
                style={{ color: isDark ? "#fff" : "#000" }}
                className={`flex items-center font-avenir capitalize`}
              >
                {i === breadcrumbs?.length - 1
                  ? breadcrumb.breadcrumb
                  : convertBreadcrumb(breadcrumb.breadcrumb)}
                <FiChevronRight
                  style={{
                    stroke: isDark ? "white" : "black",
                    strokeWidth: "4",
                  }}
                  className={i < breadcrumbs.length - 1 ? "mx-2" : "hidden"}
                />
              </Link>
            </li>
          );
        })}
      </ol>
    </StyledNav>
  );
}
